import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Spinner, Alert } from 'reactstrap';
import { Can } from '../../auth/abilityContext';
import { ModalDialog } from '../containers/dialog';
import * as request from './usersRequests';
import ErrorLine from '../containers/errorLine';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  customFormats,
  schema as schemaSource,
  uiSchema as uiSchemaSource,
} from '../../schemas/user';
import FormWrapper from '../../form/formWrapper';
import { set, cloneDeep } from 'lodash';
import submit from '../../form/syntheticSubmit';

const AddUser = ({ propertyId }) => {
  const subjectName = 'CreateUser';

  const [form, setForm] = useState();
  const [formKey, setFormKey] = useState(1);
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState(null);

  const [createUser, { loading }] = useMutation(request.CREATE_USER, {
    onCompleted: () => {
      setComplete(true);
      setFormKey(formKey + 1);
    },
    onError: error => {
      setError(error);
    },
    refetchQueries: [
      {
        query: request.GET_USERS,
      },
    ],
  });

  const onSubmit = data => {
    setError(null);
    const variables = { ...data, propertyId };
    createUser({ variables });
  };

  const success = !!complete && !loading && !error;

  const onDismiss = () => {
    setComplete(false);
    setError(null);
  };

  const setUiSchema = () => {
    const newUiSchema = cloneDeep(uiSchemaSource);
    set(newUiSchema, 'email["ui:disabled"]', false);
    set(newUiSchema, 'emailIsVerified["ui:disabled"]', false);
    set(newUiSchema, 'password["ui:widget"]', 'password');
    set(newUiSchema, 'id["ui:widget"]', 'hidden');
    return newUiSchema;
  };

  return (
    <Can I="create" a={subjectName}>
      <div>
        <ModalDialog
          buttonLabel={
            <FontAwesomeIcon icon={faPlus} className="button-icon" />
          }
          buttonColor="success"
          title="Add user"
          size="lg"
          onClose={onDismiss}
          footer={
            <Button onClick={() => submit(form)} color="success">
              Save data
            </Button>
          }
        >
          <FormWrapper
            subjectName={subjectName}
            loading={loading}
            schemaSource={schemaSource}
            uiSchemaSource={setUiSchema()}
            onSubmit={onSubmit}
            customFormats={customFormats}
            formKey={formKey}
            setForm={setForm}
            liveValidate
            clearForm={true}
            children={' '}
          />
          {loading && <Spinner color="secondary" />}
          {error && <ErrorLine error={error} />}
          {success && (
            <Alert isOpen={success} toggle={onDismiss}>
              New user has been added
            </Alert>
          )}
        </ModalDialog>
      </div>
    </Can>
  );
};

export default AddUser;
