import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import PropertyLink from '../propertyLink';
import AddUserProperty from './addUserProperty';
import DeleteUserProperty from './deleteUserProperty';

const UsersPropertiesList = ({
  usersPropertiesList = [],
  userId,
  properties,
}) => {
  return (
    <div style={{ marginTop: '-16px' }}>
      <p className="mb-2">Properties</p>
      <AddUserProperty userId={userId} properties={properties} />
      <div
        className="mb-4 border"
        style={{ maxHeight: '224px', overflow: 'auto', borderRadius: '4px' }}
      >
        <ListGroup flush>
          {usersPropertiesList.length === 0 ? (
            <ListGroupItem>No properties</ListGroupItem>
          ) : (
            <>
              {usersPropertiesList.map((p, i) => {
                return (
                  <ListGroupItem key={`property-${i}`}>
                    <div className="d-flex flex-row justify-content-between align-items-start">
                      <PropertyLink property={p.property} />
                      <DeleteUserProperty
                        userId={userId}
                        propertyId={p.property.id}
                      />
                    </div>
                  </ListGroupItem>
                );
              })}
            </>
          )}
        </ListGroup>
      </div>
    </div>
  );
};

export default UsersPropertiesList;
