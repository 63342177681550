export const schema = {
  type: 'object',
  properties: {
    id: {
      type: 'number',
      title: 'id',
    },
    username: {
      type: 'string',
      title: 'Username',
      format: 'username',
    },
    password: {
      type: 'string',
      title: 'Password',
    },
    name: {
      type: 'string',
      title: 'Name',
    },
    isVerified: {
      type: 'boolean',
      title: 'Is verified',
      default: false,
    },
    role: {
      type: 'string',
      title: 'Role',
      enum: [
        'KIOSK',
        'GUEST',
        'MANAGER',
        'ADMIN',
        'SERVICE',
        'DISTRIBUTOR',
        'RECEPTIONIST',
      ],
      enumNames: [
        'Kiosk',
        'Guest',
        'Manager',
        'Admin',
        'Service',
        'Distributor',
        'Receptionist',
      ],
      default: 'MANAGER',
    },
    avatarUrl: {
      type: ['string', 'null'],
      title: 'Avatar url',
    },
    email: {
      type: 'string',
      title: 'Email',
    },
    emailIsVerified: {
      type: 'boolean',
      title: 'Email is verified',
      default: false,
    },
  },
};

export const uiSchema = {
  'ui:order': [
    'id',
    'username',
    'password',
    'isVerified',
    'name',
    'role',
    'email',
    'emailIsVerified',
    'avatarUrl',
  ],
  'id': {
    'ui:readonly': true,
  },
  'username': {
    'ui:widget': 'CheckUsername',
  },
  'isVerified': {
    'ui:options': {
      label: false,
    },
  },
  'emailIsVerified': {
    'ui:disabled': true,
    'ui:options': {
      label: false,
    },
  },
  'password': {
    'ui:widget': 'password',
    'ui:options': {
      autocomplete: 'new-password',
    },
  },
};

export const customFormats = {
  username: /^[a-z0-9\s_-]*$/iu,
};
