import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Spinner, UncontrolledAlert, Button } from 'reactstrap';
import * as request from './usersRequests';
import { Can } from '../../auth/abilityContext';
import ErrorLine from '../containers/errorLine';
import UsersPropertiesList from './usersPropertiesList';
import {
  customFormats,
  schema as schemaSource,
  uiSchema as uiSchemaSource,
} from '../../schemas/user';
import FormWrapper from '../../form/formWrapper';
import { cloneDeep, set } from 'lodash';

const getFilteredPropertiesList = (allProperties, usersProperties) => {
  return allProperties.filter(
    p => !usersProperties.filter(up => p.id === up.property.id)[0],
  );
};

const UpdateUser = ({ row }) => {
  const subjectName = 'User';
  const userId = +row.original.id;

  const [complete, setComplete] = useState(false);
  const [formKey, setFormKey] = useState(1);

  const {
    loading: dataLoading,
    error: dataError,
    data,
  } = useQuery(request.GET_USER, {
    variables: { id: userId },
  });

  const { user = {}, propertiesList = [] } = data || {};
  const { userEmailsList } = user;
  const [userEmail = {}] = userEmailsList || [];

  const formData = {
    ...user,
    email: userEmail.email,
    emailIsVerified: userEmail.isVerified,
  };

  const filteredProperties = getFilteredPropertiesList(
    propertiesList,
    user.usersPropertiesList,
  );

  const [updateUser, { loading: updateLoading, error: updateError }] =
    useMutation(request.UPDATE_USER, {
      onCompleted: () => {
        setComplete(true);
        setFormKey(formKey + 1);
      },
    });

  const onSubmit = data => {
    const variables = { id: userId, ...data };
    updateUser({ variables });
  };

  const loading = dataLoading || updateLoading;

  const success = !!complete && !loading && !dataError && !updateError;

  const setUiSchema = () => {
    const newUiSchema = cloneDeep(uiSchemaSource);
    set(newUiSchema, 'email["ui:disabled"]', true);
    set(newUiSchema, 'emailIsVerified["ui:disabled"]', true);
    set(newUiSchema, 'password["ui:widget"]', 'hidden');
    set(newUiSchema, 'id["ui:widget"]', undefined);
    return newUiSchema;
  };

  return (
    <>
      {dataLoading && <Spinner color="secondary" />}
      {dataError && <ErrorLine error={dataError} />}
      {data && (
        <>
          <FormWrapper
            formData={formData}
            subjectName={subjectName}
            loading={loading}
            schemaSource={schemaSource}
            uiSchemaSource={setUiSchema()}
            onSubmit={onSubmit}
            customFormats={customFormats}
            formKey={formKey}
            liveValidate
          >
            <Can I="view" a="UsersPropertiesList">
              <UsersPropertiesList
                userId={user.id}
                properties={filteredProperties}
                usersPropertiesList={user.usersPropertiesList}
              />
            </Can>
            <div>
              {updateLoading && <Spinner color="secondary" />}
              {updateError && <ErrorLine error={updateError} />}
              {success && (
                <UncontrolledAlert>Data has been saved</UncontrolledAlert>
              )}
            </div>
            <Can I="update" a={subjectName}>
              <Button type="submit" color="success">
                Save data
              </Button>
            </Can>
          </FormWrapper>
        </>
      )}
    </>
  );
};

export default UpdateUser;
