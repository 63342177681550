import React from 'react';
import Layout from '../components/containers/layout';
import UsersList from '../components/users/users';

const Users = () => {
  if (typeof window === 'undefined') return null;

  return (
    <Layout>
      <UsersList />
    </Layout>
  );
};

export default Users;
