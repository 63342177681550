import React from 'react';
import { useQuery } from '@apollo/client';
import { Spinner } from 'reactstrap';
import Table from '../containers/table/table';
import ErrorLine from '../containers/errorLine';
import * as request from './usersRequests';
import SEO from '../seo';
import UpdateUser from './updateUser';
import AddUser from './addUser';

const getUserEmail = row => {
  const { userEmailsList: emails } = row;
  if (emails[0]) return emails[0].email;
  return '';
};

const UsersList = () => {
  const title = 'Users';

  const { loading, error, data } = useQuery(request.GET_USERS);
  const { usersList } = data || {};

  const columns = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id',
        width: 80,
        flexGrow: 0,
      },
      {
        Header: 'username',
        accessor: 'username',
        width: 300,
      },
      {
        Header: 'name',
        accessor: 'name',
        width: 300,
      },
      {
        Header: 'role',
        accessor: 'role',
        flexGrow: 0.5,
      },
      {
        Header: 'email',
        accessor: getUserEmail,
        width: 300,
        flexGrow: 0.5,
      },
    ],
    [],
  );

  return (
    <div className="py-4 ">
      <SEO title={title} />
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {data && (
        <>
          <Table
            title={title}
            className="py-4"
            columns={columns}
            data={usersList}
            ExpandCard={UpdateUser}
            options={{ disableFilters: true }}
            addItem={<AddUser />}
          />
        </>
      )}
    </div>
  );
};

export default UsersList;
