import React from 'react';
import { Button, Spinner } from 'reactstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@apollo/client';
import * as request from './usersRequests';

const DeleteUserProperty = ({ userId, propertyId }) => {
  const [deleteProperty, { loading, error }] = useMutation(
    request.DELETE_PROPERTY,
    {
      refetchQueries: [
        {
          query: request.GET_USER,
          variables: { id: userId },
        },
      ],
    },
  );

  const removeProperty = id => {
    const variables = { userId, propertyId: id };
    deleteProperty({ variables });
  };
  return (
    <div>
      {error && <span className="text-danger">{error.message}</span>}
      {loading && <Spinner size="sm" color="secondary" />}{' '}
      <Button
        style={{ height: '24px', width: '24px', padding: '0', border: '0' }}
        onClick={() => removeProperty(propertyId)}
        color="secondary"
        outline
      >
        <FontAwesomeIcon icon={faTimes} />
      </Button>
    </div>
  );
};

export default DeleteUserProperty;
