import React, { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from 'reactstrap';
import { useMutation } from '@apollo/client';
import * as request from './usersRequests';
import ErrorLine from '../containers/errorLine';

const AddUserProperty = ({ userId, properties = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [assignProperty, { loading, error }] = useMutation(
    request.ASSIGN_PROPERTY,
    {
      refetchQueries: [
        {
          query: request.GET_USER,
          variables: { id: userId },
        },
      ],
    },
  );

  const addProperty = id => {
    const variables = { userId, propertyId: id };
    assignProperty({ variables });
  };

  return (
    <Dropdown className="mb-2" isOpen={isOpen} toggle={toggle}>
      {properties.length > 0 && (
        <>
          <DropdownToggle caret size="sm">
            Add property
          </DropdownToggle>
          <DropdownMenu
            modifiers={{
              setMaxHeight: {
                enabled: true,
                order: 890,
                fn: data => {
                  return {
                    ...data,
                    styles: {
                      ...data.styles,
                      overflow: 'auto',
                      maxHeight: 270,
                    },
                  };
                },
              },
            }}
          >
            {properties.map(({ id, name }, i) => (
              <DropdownItem key={`${id}-${i}`} onClick={() => addProperty(id)}>
                {name}
              </DropdownItem>
            ))}
          </DropdownMenu>{' '}
        </>
      )}
      {loading && <Spinner size="sm" color="secondary" />}
      {error && <ErrorLine error={error} />}
    </Dropdown>
  );
};

export default AddUserProperty;
